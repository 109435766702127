<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header :name="$translate('wallet.title')">
                    <span class="shadow-sm rounded-md" v-if="!table.loading">
                        <button type="button" @click="funds.currency = ''; funds.visible = true"
                                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-600 hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                            <span class="pr-3"><i class="far fa-plus"></i></span>
                            {{ $translate('wallet.add.button') }}
                        </button>
                    </span>
                </Header>
            </div>
        </div>
        <AddFunds v-model:visible="funds.visible" :currency="funds.currency" />
        <Transactions v-model:visible="transactions.visible" :currency="transactions.currency" />
        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-3">
                        <div class="bg-white shadow sm:rounded-lg overflow-hidden">
                            <DataTable :table="table" :dataset="wallet" empty="wallet.no-results">
                                <template #column_wallet="{row}">
                                    {{ new Intl.NumberFormat(locale, { style: 'currency', currency: row.currency
                                    }).format(row.balance) }}
                                </template>
                                <template #image>
                                    <img src="../../assets/images/wallet.svg" class="h-32 m-auto mt-16 mb-8"/>
                                </template>
                                <template #empty>
                                    <span class="inline-flex rounded-md shadow-sm">
                                        <button type="button" @click.prevent="funds.visible = true; funds.currency = '';"
                                                class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                            {{ $translate('wallet.add.button') }}
                                        </button>
                                    </span>
                                </template>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseActionGroup, BaseTable, Pagination, TextColumn} from "@/types/table";
    import {userStore} from "@/store/user";
    import {useAxios} from "@/plugins/axios";
    import {mapActions} from "vuex";
    import Transactions from "@/pages/wallet/Transactions.vue";
    import AddFunds from "@/pages/wallet/AddFunds.vue";
    import router from "@/router";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import { Wallet } from "@/interfaces/wallet";

    export default defineComponent({
        name: "Wallet",
        components: {
            Transactions,
            AddFunds
        },
        setup() {

            const axios = useAxios();

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.None))

            //  Add table columns
            table.addColumn(new TextColumn('wallet', 'wallet.balance'))

            const buttonGroup = new BaseActionGroup();

            //  Add funds action
            buttonGroup.addAction(new BaseAction('wallet.add.button').setIcon('fal fa-wallet').onClick((record: Wallet) => {
                    funds.visible = true;
                    funds.currency = record.currency;
                })
            );

            //  Show transactions
            buttonGroup.addAction(new BaseAction('wallet.transactions.button').setIcon('fal fa-file-invoice').onClick((record: Wallet) => {
                transactions.visible = true;
                transactions.currency = record.currency;
                })
            );

            table.addActionGroup(buttonGroup);

            const funds = reactive({
                visible: false,
                currency: ''
            });

            const transactions = reactive({
                visible: false,
                currency: ''
            })


            const wallet: Array<any> = [];

            const fetchWallet = () => {
                table.loading = true;
                axios.get(`wallet`).then(response => response.data).then(response => {
                    response.current_balance.forEach((item: Wallet) => {
                        wallet.push({currency:item.currency,balance:item.balance});
                    })
                    table.loading = false;
                }).catch(() => {
                    table.loading = false;
                });
            }
            fetchWallet();

            return {
                locale: userStore.getState().locale,
                table,
                wallet,
                funds,
                transactions,
            }
        }
    })
</script>