
    import {defineComponent, reactive, watch, computed} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {NumberField, SelectField} from "@/types/field";
    import {FieldOption} from '@/types/option';
    import {useConfirm} from "@/plugins/confirm";
    import {OperatorValidationRule, RequiredValidationRule, ValidationOperator} from "@/types/validation";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {loadStripe} from '@stripe/stripe-js/pure';
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useStripe} from "@/plugins/stripe";


    export default defineComponent({
        name: "AddFunds",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            currency: {
                type: String,
                default: '',
            },
        },
        emits: ['update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();

            const confirm = useConfirm();

            const notification = useNotification();

            const stripe = useStripe();


            const form = reactive(new BaseForm());

            const funds = reactive({
                loading: true,
                currencies: [],
                transaction_percentage: 0,
                stripe_public_key: ''
            });

            let values = reactive({
                currency: '',
                amount: 0,
            });

            const canSaveFunds = (): Promise<any> => {
                if (props.currency !== '' && props.currency !== values.currency) {
                    return confirm.info('Warning', 'Are you sure you want to add these funds in your ' + values.currency + ' wallet?', 'Yes', 'No, use ' + props.currency + ' wallet instead');
                }
                return Promise.resolve();
            }

            const saveFunds = () => {
                form.setError('').submit().then(() => {
                    canSaveFunds().then(() => {
                        axios.post(`wallet/funds`, values).then(response => response.data).then(response => {
                            fundsDrawer.saving = false;
                            showDrawer();
                            stripe.payment(response.checkout.id);
                        }).catch(error => {
                            form.setError(getErrorMessage(error.response.data));
                            fundsDrawer.saving = false;
                            showDrawer();
                        })
                    }).catch(() => {
                        values.currency = props.currency;
                        fundsDrawer.saving = false;
                        showDrawer();
                    })

                }).catch(() => {
                    fundsDrawer.saving = false;
                    showDrawer();
                })
            }

            const fundsDrawer = reactive(new usePromise());
            const showDrawer = async () => {
                const confirmed = await fundsDrawer.show();
                if (confirmed) {
                    saveFunds();
                } else {
                    emit('update:visible', false);
                }
            }

            const paymentFeeModal = reactive(new usePromise());
            const showPaymentFeeModal = async () => {
                const confirmed = await paymentFeeModal.show();
                if (confirmed) {
                    console.log('Confirmed');
                    paymentFeeModal.hide();
                } else {
                    console.log('Cancelled');
                }
            }

            watch(() => props.visible, (visible) => {
                if (visible) {

                    form.removeFields();

                    values.amount = 0;
                    funds.loading = true;
                    axios.get(`app/config`)
                        .then(response => response.data)
                        .then(response => {
                            funds.currencies = response.available_currencies;
                            funds.stripe_public_key = response.stripe_public_key;
                            funds.transaction_percentage = response.transaction_percentage;
                            funds.loading = false;

                            const currencyField = new SelectField('currency', 'wallet.funds.fields.currency');
                            funds.currencies.forEach((currency: any, index: number) => {
                                currencyField.addOption(new FieldOption(currency, currency).preventTranslate())
                                if (index === 0) {
                                    values.currency = props.currency === '' ? currency : props.currency;
                                }
                            });

                            currencyField.setWidth(3);
                            form.addField(currencyField);

                            const balanceField = new NumberField('amount', 'wallet.funds.fields.amount');
                            balanceField.addRule(new RequiredValidationRule());
                            balanceField.addRule(new OperatorValidationRule(ValidationOperator.GreaterThenOrEqualTo, 100));
                            balanceField.setWidth(9);
                            form.addField(balanceField);
                        })
                        .catch(() => {
                            funds.loading = false;
                            emit('update:visible', false);
                        });


                    showDrawer();

                }
            }, {deep: true, immediate: true});

            const paymentFee = computed(() => values.amount * (funds.transaction_percentage / 100));

            return {
                funds,
                paymentFee,
                locale: userStore.getState().locale,
                form,
                values,
                fundsDrawer,
                paymentFeeModal,
                showPaymentFeeModal
            }
        }
    })
