<template>
    <Drawer :drawer="fundsDrawer" :title="$translate('wallet.funds.title')"
            :description="$translate('wallet.funds.description')"
            :saving="'wallet.funds.preparing'"
            :save="$translate('wallet.funds.pay')"
            width="max-w-3xl">
        <div class="px-4 py-5 sm:p-6" v-if="funds.loading">
            <Loading :loading="funds.loading"/>
        </div>
        <div class="px-4 py-5 sm:p-6" v-if="!funds.loading">
            <DataForm v-model:form="form" v-model="values"/>
            <div v-if="values.amount > 0">
                <label class="block text-sm font-medium leading-5 text-gray-700 mb-2 pt-6">
                    {{ $translate('wallet.funds.summery.title') }}
                </label>
                <div class="bg-white border border-gray-300 rounded-md overflow-hidden">
                    <ul>
                        <li>
                            <div class="flex items-center px-4 py-4">
                                <div class="flex-1 grid grid-cols-2 gap-4">
                                    <div class="text-sm leading-5 text-gray-900">
                                        {{ $translate('wallet.funds.summery.amount') }}
                                    </div>
                                    <div class="text-sm leading-5 text-gray-500 text-right">
                                        {{ new Intl.NumberFormat(locale, { style: 'currency', currency:
                                        values.currency}).format(values.amount) }}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="border-t border-gray-200" v-if="funds.transaction_percentage > 0">
                            <div class="flex items-center px-4 py-4">
                                <div class="flex-1 grid grid-cols-2 gap-4">
                                    <div class="text-sm leading-5 text-gray-900" @click="showPaymentFeeModal">
                                        {{ $translate('wallet.funds.summery.fee') }} <i class="fas fa-question-circle text-gray-900"></i>
                                    </div>
                                    <div class="text-sm leading-5 text-gray-500 text-right">
                                        {{ new Intl.NumberFormat(locale, { style: 'currency', currency:
                                        values.currency}).format(paymentFee) }}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="border-t border-gray-200">
                            <div class="flex items-center px-4 py-4">
                                <div class="flex-1 grid grid-cols-2 gap-4">
                                    <div class="text-sm leading-5 text-gray-900 font-medium">
                                        {{ $translate('wallet.funds.summery.total') }}
                                    </div>
                                    <div class="text-sm leading-4 text-gray-900 text-right font-medium">
                                        {{ new Intl.NumberFormat(locale, { style: 'currency', currency:
                                        values.currency}).format(parseFloat(values.amount) + parseFloat(paymentFee)) }}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Modal :modal="paymentFeeModal">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-custom-100 sm:mx-0 sm:h-10 sm:w-10">
                <i class="fas fa-info text-custom-600"></i>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    {{ $translate('wallet.funds.fee.title') }}
                </h3>
                <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                        {{ $translate('wallet.funds.fee.description', {
                        percentage: new Intl.NumberFormat(locale, { style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2 }).format(funds.transaction_percentage/100),
                        fee:new Intl.NumberFormat(locale, { style: 'currency', currency:
                        values.currency}).format(paymentFee) }) }}
                    </p>
                </div>
            </div>
            <template #footer>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button type="button" @click="paymentFeeModal.hide()"
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            {{ $translate('form.close') }}
                        </button>
                    </span>
                </div>
            </template>
        </Modal>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch, computed} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {NumberField, SelectField} from "@/types/field";
    import {FieldOption} from '@/types/option';
    import {useConfirm} from "@/plugins/confirm";
    import {OperatorValidationRule, RequiredValidationRule, ValidationOperator} from "@/types/validation";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {loadStripe} from '@stripe/stripe-js/pure';
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useStripe} from "@/plugins/stripe";


    export default defineComponent({
        name: "AddFunds",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            currency: {
                type: String,
                default: '',
            },
        },
        emits: ['update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();

            const confirm = useConfirm();

            const notification = useNotification();

            const stripe = useStripe();


            const form = reactive(new BaseForm());

            const funds = reactive({
                loading: true,
                currencies: [],
                transaction_percentage: 0,
                stripe_public_key: ''
            });

            let values = reactive({
                currency: '',
                amount: 0,
            });

            const canSaveFunds = (): Promise<any> => {
                if (props.currency !== '' && props.currency !== values.currency) {
                    return confirm.info('Warning', 'Are you sure you want to add these funds in your ' + values.currency + ' wallet?', 'Yes', 'No, use ' + props.currency + ' wallet instead');
                }
                return Promise.resolve();
            }

            const saveFunds = () => {
                form.setError('').submit().then(() => {
                    canSaveFunds().then(() => {
                        axios.post(`wallet/funds`, values).then(response => response.data).then(response => {
                            fundsDrawer.saving = false;
                            showDrawer();
                            stripe.payment(response.checkout.id);
                        }).catch(error => {
                            form.setError(getErrorMessage(error.response.data));
                            fundsDrawer.saving = false;
                            showDrawer();
                        })
                    }).catch(() => {
                        values.currency = props.currency;
                        fundsDrawer.saving = false;
                        showDrawer();
                    })

                }).catch(() => {
                    fundsDrawer.saving = false;
                    showDrawer();
                })
            }

            const fundsDrawer = reactive(new usePromise());
            const showDrawer = async () => {
                const confirmed = await fundsDrawer.show();
                if (confirmed) {
                    saveFunds();
                } else {
                    emit('update:visible', false);
                }
            }

            const paymentFeeModal = reactive(new usePromise());
            const showPaymentFeeModal = async () => {
                const confirmed = await paymentFeeModal.show();
                if (confirmed) {
                    console.log('Confirmed');
                    paymentFeeModal.hide();
                } else {
                    console.log('Cancelled');
                }
            }

            watch(() => props.visible, (visible) => {
                if (visible) {

                    form.removeFields();

                    values.amount = 0;
                    funds.loading = true;
                    axios.get(`app/config`)
                        .then(response => response.data)
                        .then(response => {
                            funds.currencies = response.available_currencies;
                            funds.stripe_public_key = response.stripe_public_key;
                            funds.transaction_percentage = response.transaction_percentage;
                            funds.loading = false;

                            const currencyField = new SelectField('currency', 'wallet.funds.fields.currency');
                            funds.currencies.forEach((currency: any, index: number) => {
                                currencyField.addOption(new FieldOption(currency, currency).preventTranslate())
                                if (index === 0) {
                                    values.currency = props.currency === '' ? currency : props.currency;
                                }
                            });

                            currencyField.setWidth(3);
                            form.addField(currencyField);

                            const balanceField = new NumberField('amount', 'wallet.funds.fields.amount');
                            balanceField.addRule(new RequiredValidationRule());
                            balanceField.addRule(new OperatorValidationRule(ValidationOperator.GreaterThenOrEqualTo, 100));
                            balanceField.setWidth(9);
                            form.addField(balanceField);
                        })
                        .catch(() => {
                            funds.loading = false;
                            emit('update:visible', false);
                        });


                    showDrawer();

                }
            }, {deep: true, immediate: true});

            const paymentFee = computed(() => values.amount * (funds.transaction_percentage / 100));

            return {
                funds,
                paymentFee,
                locale: userStore.getState().locale,
                form,
                values,
                fundsDrawer,
                paymentFeeModal,
                showPaymentFeeModal
            }
        }
    })
</script>