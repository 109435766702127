<template>
  <Drawer :drawer="transactionDrawer" :title="$translate('wallet.transactions.title')"
          :description="$translate('wallet.transactions.description')" width="max-w-4xl">
    <DataTable :table="table" :dataset="transactions.list" empty="wallet.transactions.no-results">
      <template #column_mutation="{row}">
        {{ new Intl.NumberFormat(locale, {style: 'currency', currency: row.currency}).format(row.mutation) }}
      </template>
      <template #column_balance="{row}">
        {{ new Intl.NumberFormat(locale, {style: 'currency', currency: row.currency}).format(row.balance) }}
      </template>
      <template #column_date="{value}">
        {{ date.formatDate(value) }}
      </template>
      <template #image>
        <img src="../../assets/images/transactions.svg" class="h-32 m-auto mt-16 mb-8"/>
      </template>
    </DataTable>
    <template #footer>
      <div class="flex flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
        <div class="flex-grow flex items-center">
        </div>
        <div class="flex flex-row-reverse">
          <div class="space-x-3 flex justify-end">
                        <span class="inline-flex rounded-md shadow-sm">
                            <button @click.prevent="showAddFunds" type="submit"
                                    :class="{'inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 transition duration-150 ease-in-out':true}">
                                <span class="pr-3"><i class="far fa-plus"></i></span>
                            {{ $translate('wallet.add.button') }}
                            </button>
                        </span>
          </div>
        </div>
      </div>
    </template>
    <AddFunds v-model:visible="funds.visible" :currency="currency"/>
  </Drawer>
</template>

<script lang="ts">
import {defineComponent, reactive, watch} from 'vue';
import {usePromise} from "@/components/usePromise";
import {BaseAction, BaseColumn, BaseTable, Pagination} from "@/types/table";
import AddFunds from "@/pages/wallet/AddFunds.vue";
import {userStore} from "@/store/user";
import {useDate} from "@/plugins/date";
import {useAxios} from "@/plugins/axios";
import {WalletTransaction} from "@/interfaces/wallet";

export default defineComponent({
  name: "Transactions",
  components: {
    AddFunds
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: '',
    }
  },
  emits: ['update:visible'],
  setup(props, {emit}) {

    const axios = useAxios();

    const date = useDate();

    const transactionDrawer = reactive(new usePromise());
    const showDrawer = async () => {
      const confirmed = await transactionDrawer.show();
      if (confirmed) {
        console.log('test');
      } else {
        emit('update:visible', false);
      }
    }

    const table = reactive(new BaseTable(Pagination.Numbers));

    table.addColumn(new BaseColumn('description', 'wallet.transactions.columns.description'))
    table.addColumn(new BaseColumn('mutation', 'wallet.transactions.columns.mutation'))
    table.addColumn(new BaseColumn('balance', 'wallet.transactions.columns.balance').hideMobile())
    table.addColumn(new BaseColumn('date', 'wallet.transactions.columns.date').hideMobile())

    table.addAction(new BaseAction('wallet.transactions.invoice').setIcon('fal fa-receipt').onClick((record: WalletTransaction) => {
      record.download = true;
      axios.get(
          `invoice/${record.invoice.id}/download`,
          {
            responseType: 'arraybuffer',
            headers: {
              'Accept': 'application/octet-stream'
            }
          })
          .then(response => {
            console.log(response.data);
            let blob = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Invoice_${record.invoice.id}.pdf`
            link.click();
            record.download = false;
          })
          .catch(() => {
            record.download = false;
          });
    }).invisible((record: WalletTransaction) => {
      return record.download;
    }));

    table.addAction(new BaseAction('wallet.transactions.invoice').setIcon('fal fa-spin fa-spinner-third').onClick((record: WalletTransaction) => {
    })
        .invisible((record: WalletTransaction) => {
          return !record.download;
        }));


    table.onChangedPage((page: number) => fetchTransactions(page));

    const transactions = reactive({
      list: Array<WalletTransaction>()
    });
    const fetchTransactions = (page: number = 1) => {
      table.loading = true;
      axios.get(`wallet/${props.currency}?page=${page}`)
          .then(response => response.data)
          .then(response => {
            transactions.list = [];
            table.setResults(response.meta.total);
            response.data.forEach((transaction: WalletTransaction) => {
              transaction.date = transaction.invoice.date;
              transactions.list.push(transaction);
            })
            table.loading = false;
          }).catch(() => {
        table.loading = false;
      });
    }

    watch(() => props.visible, (visible) => {
      if (visible) {
        showDrawer();
        fetchTransactions();
      }
    }, {deep: true, immediate: true})

    const funds = reactive({
      visible: false
    })

    const showAddFunds = () => {
      funds.visible = true;
    }

    return {
      locale: userStore.getState().locale,
      table,
      transactionDrawer,
      transactions,
      funds,
      showAddFunds,
      date
    }
  }
})
</script>