
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseActionGroup, BaseTable, Pagination, TextColumn} from "@/types/table";
    import {userStore} from "@/store/user";
    import {useAxios} from "@/plugins/axios";
    import {mapActions} from "vuex";
    import Transactions from "@/pages/wallet/Transactions.vue";
    import AddFunds from "@/pages/wallet/AddFunds.vue";
    import router from "@/router";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import { Wallet } from "@/interfaces/wallet";

    export default defineComponent({
        name: "Wallet",
        components: {
            Transactions,
            AddFunds
        },
        setup() {

            const axios = useAxios();

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.None))

            //  Add table columns
            table.addColumn(new TextColumn('wallet', 'wallet.balance'))

            const buttonGroup = new BaseActionGroup();

            //  Add funds action
            buttonGroup.addAction(new BaseAction('wallet.add.button').setIcon('fal fa-wallet').onClick((record: Wallet) => {
                    funds.visible = true;
                    funds.currency = record.currency;
                })
            );

            //  Show transactions
            buttonGroup.addAction(new BaseAction('wallet.transactions.button').setIcon('fal fa-file-invoice').onClick((record: Wallet) => {
                transactions.visible = true;
                transactions.currency = record.currency;
                })
            );

            table.addActionGroup(buttonGroup);

            const funds = reactive({
                visible: false,
                currency: ''
            });

            const transactions = reactive({
                visible: false,
                currency: ''
            })


            const wallet: Array<any> = [];

            const fetchWallet = () => {
                table.loading = true;
                axios.get(`wallet`).then(response => response.data).then(response => {
                    response.current_balance.forEach((item: Wallet) => {
                        wallet.push({currency:item.currency,balance:item.balance});
                    })
                    table.loading = false;
                }).catch(() => {
                    table.loading = false;
                });
            }
            fetchWallet();

            return {
                locale: userStore.getState().locale,
                table,
                wallet,
                funds,
                transactions,
            }
        }
    })
